import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import InitializeData from '../utils/InitializeData';

export default function Load() {
    const navigate = useNavigate();
    useEffect(()=> {
        navigate('/main');
    }, []);

    return (
        <>
            {/* <InitializeData /> */}
            <p>Loading...</p>
        </>
        
    )
}