function AuthButton({style}) {
    const handleAuth = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/auth`;
    };

    return (
        <button 
            onClick={handleAuth}
            className={style}
            // className="px-4 py-2 text-stone-100 bg-gradient-to-r from-stone-300 to-stone-400 rounded-lg drop-shadow-lg"   
        >
            Connect to Sonos
        </button>
    )
}

export default AuthButton;