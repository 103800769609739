import Aside from './Aside';
import HomePage from './HomePage';
import MusicPage from './MusicPage';
import GroupsPage from './GroupsPage';
import InitializeData from '../utils/InitializeData';
import SubscriptionHandler from '../websocket/SubscriptionHandler';

import { useState } from 'react';

export default function Main() {
    const [page, setPage] = useState('Home');

    function setPageFunction(page) {
        setPage(page);
      }

    return (
        <main className="h-full my-8 flex gap-8 object-contain">
        <Aside setPage={setPageFunction} page={page}/>
        <div className="p-10 mx-5 max-h-fit rounded-lg bg-white flex-1 drop-shadow-lg" >
            <h2 className="text-2xl font-semibold pb-5">
              {page}
            </h2>
            {page === 'Home' &&
              <HomePage />
            }
            {page === 'Music' && 
              <MusicPage setPage={setPageFunction}/>
            }
            
            
        </div>
      </main>
    )
}