import './App.css';

import { useEffect, useState, useContext } from 'react';
import {SystemContext} from './components/SystemContext';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

import Main from './components/Main';
import Login from './components/Login';
import AuthPage from './components/AuthPage';
import Load from './components/Load';
import SubscriptionHandler from './websocket/SubscriptionHandler';

function App() {
  
  const {systemDataState, refreshRequest} = useContext(SystemContext);
  const [systemData, setSystemData] = systemDataState;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasToken, setHasToken] = useState(false);

  function handleLogin() {
    setIsAuthenticated(true);
  }

  useEffect(()=> {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const initToken = systemData.accessToken;

    if (code) {
      sendCodeToServer(code);
    }

    if (systemData.accessToken !== null && systemData.tokenExpiration > Date.now()) {
      setHasToken(true);
    } else if (systemData.tokenExpiration <= Date.now()) {
      refreshRequest();
      if (initToken !== systemData.accessToken) {
        setHasToken(true);
      }
    }

  },[]);

  const sendCodeToServer = async (code) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({code: code}),
      });
      
      const data = await response.json();
      console.log('Server response: ', data);
  
      if (data.access_token) {
        const issuedAt = Date.now();
        const validityDuration = 86399;
        const expirationTime = issuedAt + validityDuration * 1000;

        console.log(`Setting access token in localStorage: ${data.access_token}`);
        console.log(`Expires: ${expirationTime}`);
        console.log(`Setting refresh token: ${data.refresh_token}`);
        localStorage.setItem('accessToken', data.access_token);
        localStorage.setItem('tokenExpiration', expirationTime);
        localStorage.setItem('refreshToken', data.refresh_token);

        setSystemData((prevSystemData)=> ({
          ...prevSystemData,
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
          tokenExpiration: expirationTime
        }));

        setHasToken(true);

      }
    } catch (error) {
      console.error('Failed to send code to server: ', error);
    }
  }
  
  return (
    <>
    <div className="bg-stone-100 h-screen overflow-hidden p-0 m-0">
      <header className="border-b-2 grid grid-cols-2 place-content-between">
        <h1 className="font-bold text-3xl p-5 drop-shadow-lg">
          Sonos Player
        </h1>
        <div className="text-right justify right py-5 px-5">
          <button className="text-right border-2 border-stone-600 hover:bg-stone-600 hover:text-stone-100 justify-right px-4 py-2 rounded-full">
            Switch User
          </button>
        </div>
        
      </header>
      <Router>
        <Routes>
          <Route path="/" element={hasToken ? <Navigate to="/login" /> : <AuthPage />}/>
          <Route path="/load" element={<Load />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/load" /> : <Login onLogin={handleLogin} />}/>
          <Route path="/main" element={<Main />}/>
        </Routes>
      </Router>
    </div>
    <SubscriptionHandler />
    
    </>
  );
}

export default App;
