// import axios from 'axios';
import { useState, useContext, useEffect } from 'react';
import {SystemContext} from './SystemContext';

export default function VolumeSlider({groupId}) {

    const [volume, setVolume] = useState(0);
    const {systemDataState, refreshRequest} = useContext(SystemContext);
    const [systemData, setSystemData] = systemDataState;
    const token = systemData.accessToken;
    const householdId = systemData.householdId;

    useEffect(()=>{
        getVolume();
    }, []);

    function handleVolumeChange(event) {
        setVolume(event.target.value);
    }

    const getVolume = async () => {
        if (Date.now() >= parseInt(systemData.tokenExpiration)){
            refreshRequest();
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get-volume`, {
                method: 'GET',
                'Content-Type': 'application/json',
                headers: {
                    'authorization': `Bearer ${token}`,
                    'group-id': groupId
                },
            });
            const data = await response.json();
            console.log('Volume change data:', data);
            setVolume(data.volume);
            
        } catch (error) {
            console.error('Error playing:', error);
        } 
    }

    const sendVolumeChangeRequest = async (sentVolume) => {
        if (Date.now() >= parseInt(systemData.tokenExpiration)){
            refreshRequest();
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/change-volume`, {
                method: 'POST',
                'Content-Type': 'application/json',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'group-id': groupId,
                    'volume': sentVolume
                },
                // body: {'volume': sentVolume}
            });
            const data = await response.json();
            console.log('Volume change data:', data);
            
        } catch (error) {
            console.error('Error playing:', error);
        } 
    }

    function handleVolumeFinalize() {
        sendVolumeChangeRequest(volume);
    }


    return (
        <>
            <div className="pt-4 w-full">
                <input 
                    style={{
                        WebkitAppearance: 'none', /* Capital 'W' and no hyphen, camelCase format */
                        appearance: 'none',
                        width: '100%', /* Ensure percentage values are in quotes */
                        height: '15px', /* Ensure pixel values are in quotes */
                        background: '#ddd', /* Colors must be in quotes */
                        borderRadius: '10px'
                    }}
                    type="range" 
                    min="1" 
                    max="50" 
                    value={volume}
                    className="slider"
                    onChange={handleVolumeChange}
                    onMouseUp={handleVolumeFinalize}
                    onTouchEnd={handleVolumeFinalize}
                />
                <style>
                    {`
                    input[type="range"]::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        width: 30px;
                        height: 30px;
                        background: linear-gradient(90deg, #14ADCC, #3477EF);
                        cursor: pointer;
                        border-radius: 15px;
                        box-shadow: 2px 2px 2px gray;
                    }
                    input[type="range"]::-moz-range-thumb {
                        width: 30px;
                        height: 30px;
                        background: linear-gradient(90deg, #14ADCC, #3477EF);
                        cursor: pointer;
                        border-radius: 15px;
                    }
                    input[type="range"]:focus::-webkit-slider-thumb {
                        box-shadow: 0 0 0 3px #ffcc00;
                    }
                    input[type="range"]:focus::-moz-range-thumb {
                        box-shadow: 0 0 0 3px #ffcc00;
                    }
                    `}
                </style>
                <p className="text-stone-800 justify-center">Volume: {volume}</p>
            </div>
            
        </>
        
    )
}