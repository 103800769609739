import AuthButton from "./AuthButton";
import GetHouseHoldButton from "./GetHouseHoldButton";

export default function Aside({setPage, page}){

    const mainButtonStyle = "flex justify-between my-4 p-2 text-stone-500 font-semibold hover:text-stone-700";
    const mainButtonStyleSelected = "flex justify-between my-4 p-2 text-stone-800 font-semibold border-l-2 border-stone-400";
    const adminButtonStyle = "flex justify-between my-4 p-2 text-stone-400 hover:bg-stone-600 hover:rounded-md hover:text-stone-100";

    const buttons = [
        {page: 'Home'},
        // {page: 'Groups'},
        {page: 'Music'}
    ];

    function handleClick(button) {
        setPage(button.page);
    }

    return (
        <aside className="px-8 ">
          <ul>
            {buttons.map(button => (
                <li key={button.page}>
                    <button
                        className={page === button.page ? mainButtonStyleSelected : mainButtonStyle}
                        onClick={()=> handleClick(button)}
                    >
                        {button.page}
                    </button>
                </li>
            ))}
          </ul>
          
          {/* <ul>
            <li>
                <button className={mainButtonStyleSelected} onClick={()=>{handleClick('home')}}>
                    Home
                </button>
            </li>
            <li>
                <button className={mainButtonStyle}>
                    Music
                </button>
            </li>
            <li>
                <button className={mainButtonStyle}>
                    Select Groups
                </button>
            </li>
          </ul> */}
          {/* <ul className="absolute bottom-0 py-5">
            <li>
              <AuthButton style={adminButtonStyle} />
            </li>
            <li>
              <GetHouseHoldButton style={adminButtonStyle}/>
            </li>
          </ul> */}
          {/* <ul className="p-4 mt-8">
            {listOfProjects.map((project, index) =>(
                <li key={index}>
                    <button 
                        className={index === selectedProjectIndex ? selectedButtonStyle : unSelectedButtonStyle}
                        onClick={()=> selectProject(index, project.title)}
                    >{project.title}</button>
                </li>
            ))}
          </ul> */}
        </aside>
          
    );
}