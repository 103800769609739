import AuthButton from "./AuthButton";

import {SystemContext} from '../components/SystemContext.js';
import { useEffect, useContext } from 'react';
import InitializeData from "../utils/InitializeData";

export default function Login({onLogin}) {
    const {systemDataState} = useContext(SystemContext);
    const [systemData, setSystemData] = systemDataState;

    function handleLoginClick() {
        //call api and get info

        onLogin();
    }

    return (
        <>
            <div className="text-center mt-5 p-10 mx-5 max-h-fit rounded-lg bg-white flex-1 drop-shadow-lg">
                <h2 className="text-xl">Welcome the the Sonos Player</h2>
                <button
                    className="text-right mt-5 border-2 border-stone-600 hover:bg-stone-600 hover:text-stone-100 justify-right px-4 py-2 rounded-full"
                    onClick={handleLoginClick}
                >
                    Login
                </button>
            </div>
            <InitializeData />
        </>
    )
}