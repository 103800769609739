import AuthButton from "./AuthButton";

export default function AuthPage() {


    return (
        <>
            <div className="text-center mt-5 p-10 mx-5 max-h-fit rounded-lg bg-white flex-1 drop-shadow-lg">
                <h2 className="text-xl">Welcome the the Sonos Player</h2>
                <br></br>
                <AuthButton style="text-right border-2 border-stone-600 hover:bg-stone-600 hover:text-stone-100 justify-right px-4 py-2 rounded-full"/>
            </div>
            
        </>
    )
}