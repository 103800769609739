import { createContext, useState, useEffect } from "react";

export const SystemContext = createContext(null);

export const SystemProvider = ({ children }) => {
    const [systemData, setSystemData] = useState({
        accessToken: '',
        tokenExpiration: '',
        refreshToken: '',
        householdId: '',
        playlistId: '',
        playlistName: '',
    });

    const [groupArray, setGroupArray] = useState([]);
    
    // const [playbackStates, setPlaybackStates] = useState([]);

    // const updatePlaybackState = (groupId, playbackState) => {
    //     setPlaybackStates(prev => {
    //         const groupNameIndex = null;
    //         const groupName = null;
    //         if (systemData.groupArray !== null){
    //             groupNameIndex = systemData.groupArray.findIndex(item => item.groupId === groupId);
    //             groupName = systemData.groupArray[groupNameIndex].name;
    //         }

    //         if (!Array.isArray(prev)) {
    //             return [{groupId, groupName, playbackState}]
    //         }
    //         const updatedStates = [...prev];
    //         const groupIndex = updatedStates.findIndex(item => item.groupId === groupId);
            
    //         if (groupIndex !== -1) {
    //             updatedStates[groupIndex].playbackState = playbackState;
    //             console.log(`updating ${updatedStates[groupIndex].groupName} with state: ${updatedStates[groupIndex].playbackState}`);
    //         } else {
    //             updatedStates.push({groupId, groupName, playbackState});
    //             console.log(`adding: ${groupId}, with name: ${groupName}, with state: ${playbackState}`)
    //         }
    //     });
    // }

    const refreshRequest = async ()=> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/refresh-token`, {
                method: 'POST',
                'Content-Type': 'application/json',
                headers: {
                    'Authorization': `Bearer ${systemData.accessToken}`,
                    'refresh': systemData.refreshToken
                }
            });
            const data = await response.json();
            if (response.ok) {
                if (data.access_token) {
                    const issuedAt = Date.now();
                    const validityDuration = 86399;
                    const expirationTime = issuedAt + validityDuration * 1000;
            
                    console.log(`Setting access token in localStorage: ${data.access_token}`);
                    console.log(`Expires: ${expirationTime}`);
                    console.log(`Setting refresh token: ${data.refresh_token}`);
                    localStorage.setItem('accessToken', data.access_token);
                    localStorage.setItem('tokenExpiration', expirationTime);
                    localStorage.setItem('refreshToken', data.refresh_token);
            
                    setSystemData((prevSystemData)=> ({
                      ...prevSystemData,
                      accessToken: data.access_token,
                      refreshToken: data.refresh_token,
                      tokenExpiration: expirationTime
                    }));
                  }
            } else {
                throw new Error(`Server responded with status: ${response.status}`);
            }
            
        } catch (error) {
            console.error('Error refreshing:', error);
        }
    };


    useEffect(()=> {
        const storedData = {
            accessToken: localStorage.getItem('accessToken'),
            tokenExpiration: localStorage.getItem('tokenExpiration'),
            refreshToken: localStorage.getItem('refreshToken'),
            householdId: localStorage.getItem('householdId'),
            playlistId: localStorage.getItem('playlistId'),
            playlistName: localStorage.getItem('playlistName'),
        };
        console.log(storedData);
        setSystemData(storedData);


    },[]);

    const value = {
        systemDataState: [systemData, setSystemData],
        refreshRequest,
        groupArray,
        setGroupArray
    };

    return (
        <SystemContext.Provider value={value}>
            {children}
        </SystemContext.Provider>
    );

};