import PlayButton from "./PlayButton";
import VolumeSlider from "./VolumeSlider";
import InitializeData from "../utils/InitializeData";

import {SystemContext} from './SystemContext';
import {useContext} from 'react';

export default function HomePage () {

    const {systemDataState, refreshRequest, groupArray, setGroupArray} = useContext(SystemContext);

    
    return (
        <div className="grid grid-flow-row-dense auto-cols-auto">
            {!groupArray ? (
                <p>Loading ...</p>
            ) : (
                groupArray.map((group, index) => (
                    <div key={index} className="text-center max-w-80 bg-stone-100 p-5 m-3 rounded-lg drop-shadow-lg">
                        <h2 
                            className={'mb-3 text-3xl font-semibold text-stone-800'}
                        >
                            {group.name}
                        </h2>
                        <PlayButton groupId={group.id} />
                        <VolumeSlider groupId={group.id}/>
                    </div>
            )))}
        </div>
    )
}

