import {SystemContext} from './SystemContext';
import {useContext, useState, useEffect} from 'react';

export default function MusicPage ({setPage}) {

    const {systemDataState, refreshRequest} = useContext(SystemContext);
    const [systemData, setSystemData] = systemDataState;

    const token = systemData.accessToken;
    const householdId = systemData.householdId;
    const [playlists, setPlaylists] = useState([]);

    useEffect(()=> {
        fetchPlaylists();
    },[]);

    const fetchPlaylists = async () => {
        if (Date.now() >= parseInt(systemData.tokenExpiration)){
            refreshRequest();
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch-playlists`, {
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`,
                    'household-id': householdId,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error, status: ${response.status}, house: ${householdId}`);
            }

            const data = await response.json();
            console.log('Playlist data:', data);
            setPlaylists([]);
            for (let i=0; i< data.playlists.length; i++) {
                setPlaylists((prevPlaylists) => ([
                    {
                        name: data.playlists[i].name,
                        id: data.playlists[i].id
                    },
                    ...prevPlaylists
                ]));
            }
        } catch (error) {
            console.error('Error fetching playlists:', error);
        } 
    }

    const sendPlaylist = async (id) => {
        if (Date.now() >= parseInt(systemData.tokenExpiration)){
            refreshRequest();
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/set-playlist`, {
                method: 'POST',
                'Content-Type': 'application/json',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'group-id': systemData.groupId,
                    'playlist-id': id
                }
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Playlist data:', data);
            } else {
                throw new Error(`Server responded with status for playlist: ${response.status}`);
            }
            
        } catch (error) {
            console.error('Error sending playlist:', error);
        } 
    }

    function handleClick(id, name) {
        setSystemData((prevSystemData)=>({
            ...prevSystemData,
            playlistId: id,
            playlistName: name
        }));
        console.log(id);
        console.log(name);
        console.log(systemData.playlistId);

        sendPlaylist(id);
        setPage('Home');
    }

    return (
        <div className=" mt-5 max-h-56 text-center bg-stone-100 p-5 rounded-lg drop-shadow-lg">
            <h2 className={'mb-5 text-3xl font-semibold text-stone-800'}>
                Select a Playlist
            </h2>
            <ul className="pb-5 grid grid-flow-row-dense grid-cols-5 grid-rows-3 overflow-auto">
                {playlists.map((playlist, index) => (
                    <li 
                        key={index}
                        className="py-1 px-1"
                    >
                        <button 
                            onClick={()=> handleClick(playlist.id, playlist.name)}
                            className="py-1 px-2 border-2 rounded-lg hover:bg-stone-500 hover:text-stone-100 line-clamp-1 text-ellipsis h-14 w-20 hover:h-30"
                        >
                            {playlist.name}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
}