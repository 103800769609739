import { useState, useEffect, useContext } from 'react';
import {SystemContext} from './SystemContext';
import spinner from '../images/spinner.png';

export default function PlayButton({groupId}) {
    
    const {systemDataState, refreshRequest, groupArray, setGroupArray} = useContext(SystemContext);
    const [systemData, setSystemData] = systemDataState;
    const currentIndex = groupArray.findIndex(item => item.id === groupId);

    const [ready, setReady] = useState(false);

    useEffect(()=> {
        const timer = setTimeout(()=> {
            setReady(true);
        }, 1000);

        return ()=> clearTimeout(timer);
    },[]);

    useEffect(()=>{
        sendStateRequest();
    }, [ready]);

    const sendStateRequest = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get-play-state`, {
                method: 'GET',
                'Content-Type': 'application/json',
                headers: {
                    'authorization': `Bearer ${systemData.accessToken}`,
                    'group-id': groupId
                }
            });
            const data = await response.json();
            console.log(data);
            //console.log('PlaybackState data:', data.playbackState);
            if (data.playbackState) {
                const newArray = groupArray;
                if (newArray !== null) {
                    console.log(`state request if statement has passed, newArray= ${JSON.stringify(newArray)}`);
                   const matchingIndex = newArray.findIndex(item => item.id === groupId);
                   if (matchingIndex !== -1) {
                    console.log(`state request matching index passed: ${matchingIndex}`);
                    newArray[matchingIndex].playbackState = data.playbackState;
                    console.log(`state request updated array ${newArray[matchingIndex].name} with state ${data.playbackState}`);
                   } 
                   setGroupArray(newArray);
                } 
                
                // setPlayState(data.playbackState);
                // setSystemData((prevData)=> ({
                //     ...prevData,
                //     playState: data.playbackState
                // }));
                // setSystemData((prevData)=> {
                //     const newState = { ...prevData };
                //     const matchingIndex = newState.groupArray.findIndex(item => item.groupId === groupId);

                //     if (matchingIndex !== -1) {
                //         const updatedGroup = { ...newState.groupArray[matchingIndex]};
                //         updatedGroup.playbackState = data.playbackState;

                //         newState.groupArray = [
                //             ...newState.groupArray.slice(0, matchingIndex),
                //             updatedGroup,
                //             ...newState.groupArray.slice(matchingIndex +1)
                //         ];

                //     }
                    
                //     return newState;
                // });
            }
            
        } catch (error) {
            console.error('Error getting play state:', error);
        } 
    }
    
    const sendPlayRequest = async () => {
        if (Date.now() >= parseInt(systemData.tokenExpiration)){
            refreshRequest();
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/play`, {
                method: 'POST',
                'Content-Type': 'application/json',
                headers: {
                    'Authorization': `Bearer ${systemData.accessToken}`,
                    'group-id': groupId
                }
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Play data:', data);
                //setPlayState('PLAYBACK_STATE_PLAYING');
                // setSystemData((prevData)=> ({
                //     ...prevData,
                //     playState: 'PLAYBACK_STATE_PLAYING'
                // }));
            } else {
                throw new Error(`Server responded with status: ${response.status}`);
            }
            
        } catch (error) {
            console.error('Error playing:', error);
        } 
    }

    const sendPauseRequest = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/pause`, {
                method: 'POST',
                'Content-Type': 'application/json',
                headers: {
                    'Authorization': `Bearer ${systemData.accessToken}`,
                    'group-id': groupId
                }
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Pause data:', data);
                //setPlayState('PLAYBACK_STATE_PAUSED');
                // setSystemData((prevData)=> ({
                //     ...prevData,
                //     playState: 'PLAYBACK_STATE_PAUSED'
                // }));
            } else {
                throw new Error(`Server responded with status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error pausing:', error);
        } 
    }

    return (
        <>
        {
            groupArray[currentIndex].playbackState === 'PLAYBACK_STATE_PAUSED'
            && (
                <button 
                    className="px-5 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full drop-shadow-lg text-stone-100"    
                    onClick={sendPlayRequest}
                >
                    Play
                </button>
            )
        }
        {
            groupArray[currentIndex].playbackState === 'PLAYBACK_STATE_IDLE'
            && (
                <button 
                    className="px-5 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full drop-shadow-lg text-stone-100"    
                    onClick={sendPlayRequest}
                >
                    Play
                </button>
            )
        }
        {
            groupArray[currentIndex].playbackState === 'PLAYBACK_STATE_PLAYING' && (
                <button 
                    className="px-5 py-2 bg-gradient-to-r from-pink-500 to-orange-500 rounded-full drop-shadow-lg text-stone-100"    
                    onClick={sendPauseRequest}
                >
                    Pause
                </button>
            )
        }
        {
            groupArray[currentIndex].playbackState === 'PLAYBACK_STATE_BUFFERING' && (
                <button 
                    className="px-5 py-2 bg-gradient-to-r from-pink-500 to-orange-500 rounded-full drop-shadow-lg text-stone-100"   
                >
                    <img 
                        className="animate-spin max-h-6"
                        src={spinner} 
                    />
                </button>
            )
        }

        </>
    )
}