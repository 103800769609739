import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SystemProvider } from './components/SystemContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SystemProvider>
    <App />
  </SystemProvider>  
);