import {SystemContext} from '../components/SystemContext';
import {useContext, useEffect, useState} from 'react';

export default function InitializeData() {
    const {systemDataState, refreshRequest, groupArray, setGroupArray} = useContext(SystemContext);
    const [systemData, setSystemData] = systemDataState;
    const [subscribeReady, setSubscribeReady] = useState(false);
    const token = systemData.accessToken;

    useEffect(()=>{
        console.log('fetching households');
        fetchHouseholds();
    }, []);

    useEffect(()=>{
        console.log('fetching groups');
        fetchGroups();
        setSubscribeReady(true);
    }, [systemData.householdId]);

    useEffect(()=>{
        console.log('subscribing to groups');
        groupArraySubscribe();
    }, [subscribeReady]);



    const groupArraySubscribe = async () => {
        for (let i=0; i<groupArray.length; i++){
            playbackSubscribe(i);
            console.log(`subscribed at ${i}`);
        }
    }

    const fetchHouseholds = async () => {
        if (Date.now() >= parseInt(systemData.tokenExpiration)){
            await refreshRequest();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch-households`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error, status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Households data:', data);
            console.log(`set id to: ${data.households[0].id}`);
            setSystemData((prevSystemData)=> ({
                ...prevSystemData,
                householdId: data.households[0].id
            }));
        } catch (error) {
            console.error('Error fetching households:', error);
        } 
    }

    const fetchGroups = async () => {
        if (Date.now() >= parseInt(systemData.tokenExpiration)){
            await refreshRequest();
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/fetch-groups`, {
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`,
                    'household-id': systemData.householdId,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error, status: ${response.status}, house: ${systemData.householdId}`);
            }

            const data = await response.json();
            console.log('Group data:', data);
            if (data !== null) {
                setGroupArray(()=> data.groups.map(group => ({
                    id: group.id,
                    name: group.name,
                    playbackState: 'PLAYBACK_STATE_PAUSED'
                })));
            }
            

            // for (let i=0; i<data.groups.length; i++) {
            //     console.log(`group id set: ${data.groups[i].id}`);
            //     console.log(`group name set: ${data.groups[i].name}`);
            //     setGroupArray((prev) => [
            //         ...prev,
            //         {
            //             id: data.groups[i].id,
            //             name: data.groups[i].name,
            //             playbackState: 'PLAYBACK_STATE_PAUSED'
            //         }
            //     ]);
            // }
            
        } catch (error) {
            console.error('Error fetching groups:', error);
        } 
    }

    // const fetchMetadata = async () => {
    //     if (Date.now() >= parseInt(systemData.tokenExpiration)){
    //         refreshRequest();
    //     }

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_API_URL}/get-metadata`, {
    //             method: 'GET',
    //             headers: {
    //                 'authorization': `Bearer ${token}`,
    //                 'group-id': systemData.groupId,
    //                 'Content-Type': 'application/json'
    //             }
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error, status: ${response.status}, house: ${systemData.householdId}`);
    //         }

    //         const data = await response.json();
    //         console.log('Metadata:', data);
            
    //     } catch (error) {
    //         console.error('Error fetching metadata:', error);
    //     } 
    // }

    const playbackSubscribe = async (groupPosition) => {
        if (Date.now() >= parseInt(systemData.tokenExpiration)){
            refreshRequest();
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playback-subscribe`, {
                method: 'POST',
                headers: {
                    'authorization': `Bearer ${token}`,
                    'group-id': groupArray[groupPosition].id,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error, status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Subscription data:', JSON.stringify(data));
            console.log(`using group ${groupArray[groupPosition].name} at position ${groupPosition}`);
        } catch (error) {
            console.error('Error subscribing:', error);
        } 
    }
}