import { useEffect, useContext } from 'react';

import {SystemContext} from '../components/SystemContext.js';

export default function SubscriptionHandler() {
    const { systemData, groupArray, setGroupArray } = useContext(SystemContext);
    console.log(groupArray);
    useEffect(()=> {
        const ws = new WebSocket(`wss://sonos-controller-server-fd68a2581ce3.herokuapp.com`);

        ws.onopen= ()=> {
            console.log('Websocket connected');

            // // Send a ping message every 30 seconds
            // const pingInterval = setInterval(() => {
            //     if (ws.readyState === WebSocket.OPEN) {
            //         ws.send(JSON.stringify({ type: 'ping' }));
            //     }
            // }, 30000);

            // ws.onclose = () => {
            //     clearInterval(pingInterval);
            //     console.log('WebSocket disconnected');
            // };
        };

        ws.onerror = (error)=> {
            console.error('Websocket error', error);
        };

        ws.onmessage = (event)=> {
            const notification = JSON.parse(event.data);
            console.log('Received data: ', notification);
            console.log(groupArray);

            if (notification.body.playbackState) {
                console.log(`received group: ${notification.headers['x-sonos-target-value']}`);
                console.log(`received playback data: ${notification.body.playbackState}`);
                console.log(`Existing array: ${JSON.stringify(groupArray)}`);
                const receivedGroupId = notification.headers['x-sonos-target-value'];
                const newArray = [...groupArray];

                if (newArray.length !== 0) {
                    console.log(`new array if statement has passed: ${JSON.stringify(newArray)}`);
                    const matchingIndex = newArray.findIndex(item => item.id === receivedGroupId);
                    
                    if (matchingIndex !== -1) {
                        console.log(`matching index passed: ${matchingIndex}`);
                        newArray[matchingIndex].playbackState = notification.body.playbackState;
                        console.log(`updated array ${newArray[matchingIndex].name} with state ${notification.body.playbackState}`);
                   } 
                   setGroupArray(newArray);
                } 
                

                //updatePlaybackState(receivedGroupId, notification.body.playbackState);
                console.log(`received playback state: ${notification.body.playbackState}`);
                console.log(`from group: ${receivedGroupId}`);
            }
            
        };

        return ()=> {
            ws.close();
            console.log('Websocket disconnected');
        };
    }, [groupArray, setGroupArray]);




}